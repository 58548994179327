<template>

  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
     class="header"
   
  > 
    <v-icon v-if="!offDrawer()" class="mr-3" elevation="1" fab small disabled></v-icon>
    <v-btn
      v-else
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="!value"> mdi-dots-vertical </v-icon>
    </v-btn>
     <v-icon large class="mr-4">mdi-monitor-dashboard</v-icon>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text=this.$store.state.CurrentActiveDashboard.libelle
    />

    <v-spacer />
    <v-col
        class="d-flex"
        cols="6"
        sm="3"
        style="margin-top: 29px !important;"
      >
       <v-select
          v-model="select"
          :items="DataItem"
          :label="label"
          :clearable="true"
           :key="componentKey"
          @change="setSelected"
          standard
          style="z-index: 234 !important; width: 200px !important"
        >
         </v-select>
    </v-col>
      
    <div class="mx-2" />
    <div class="control">

     <v-switch class="myswitch" v-model="$vuetify.theme.dark"></v-switch>
    <v-btn class="ml-2" min-width="0" text to="/login">
      <v-icon>mdi-logout-variant</v-icon>
    </v-btn>
    <v-btn class="ml-2" min-width="0" text to="/compte">
      <v-icon>mdi-account</v-icon>
    </v-btn>

    </div>
  </v-app-bar>

</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",
  computed:{
    dataItem() {
      return this.$store.state.DataItem;
    }
  },

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    DashboardName:{
      type:String
    }
  },

  data: () => ({
     componentKey:0,
     select: null,
   /*  text:'un texte',
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ], */
  }),

  computed: {
    ...mapState(["drawer"]),
    selectedValue(){ return this.$store.state.selectedValue},
    DataItem(){return this.$store.state.DataItem},
    label(){return this.$store.state.label}
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    offDrawer() {
      if (this.$router.currentRoute.path === "/client") {
        return false;
      } else {
        return true;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    setSelected()
    {
      //console.log(this.select);
      this.$store.commit('ChartSelected',this.select);
      this.select=null;
      this.forceRerender();
    }
  },
};
</script>
<style scoped>
.v-text-field fieldset, .v-text-field .v-input__control
{
  margin-top: 30px !important;
}
.header {
  position:fixed !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(238, 232, 232, 0.24) !important;
    
    z-index: 233;
  

}
 .header.theme--light {
background:#fff!important;
  }
  .header.theme--dark{
    background-color: black !important;

  }
.myswitch{
  display: inline-block !important;
}

</style>
